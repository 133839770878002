import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import { setStorageData} from "../../../framework/src/Utilities";
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";

interface SignupInfo {
  name: string;
  email: string;
  password: string;
  confirmPassword: string;
  privacyPolicy: boolean;
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
}

export interface S {
  // Customizable Area Start
  firstName: string;
  emailApiError: string,
  isApiError: boolean,
  otpEmail:string;
  emailToken:string;
  lastName: string;
  email: string;
  password: string;
  otpAuthToken: string;
  reTypePassword: string;
  data: any[];
  passwordHelperText: string;
  enablePasswordField: boolean;
  enableReTypePasswordField: boolean;
  countryCodeSelected: string;
  phone: string;
  showPassword:boolean;
  newPassword:string;
  confirmPassword:string;
  showConfirmPassword:boolean;
  isChecked:boolean;
  nameError:boolean;
  emailError:boolean;
  passwordError:boolean;
  confirmPasswordError:boolean;
  privacyPolicyError:boolean;
  signupInfo:SignupInfo | null
  // Customizable Area End
}

export interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class EmailAccountRegistrationController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  arrayholder: any[];
  passwordReg: RegExp;
  emailReg: RegExp;
  createAccountApiCallId: any;
  validationApiCallId: string = "";

  imgPasswordVisible: any;
  imgPasswordInVisible: any;

  labelHeader: any;
  labelFirstName: string;
  lastName: string;
  labelEmail: string;
  labelPassword: string;
  labelRePassword: string;
  labelLegalText: string;
  labelLegalTermCondition: string;
  labelLegalPrivacyPolicy: string;
  btnTextSignUp: string;

  currentCountryCode: any;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage)
    ];
    this.receive = this.receive.bind(this);
    this.isStringNullOrBlank = this.isStringNullOrBlank.bind(this);

    runEngine.attachBuildingBlock(this, this.subScribedMessages);

    this.state = {
      // Customizable Area Start
      emailApiError: "",
      isApiError: false,    
      showPassword:false,
      otpEmail:"",
      emailToken:"",
      emailError:false,
      nameError:false,
      passwordError:false,
      confirmPasswordError:false,
      privacyPolicyError:false,
      isChecked:false,
      confirmPassword:"",
      showConfirmPassword:false,
      newPassword:"",
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      reTypePassword: "",
      otpAuthToken: "",
      data: [],
      passwordHelperText: "",
      enablePasswordField: true,
      enableReTypePasswordField: true,
      countryCodeSelected: "",
      phone: "",
      signupInfo:null
      // Customizable Area End
    };

    // Customizable Area Start
    this.arrayholder = [];
    this.passwordReg = new RegExp("\\w+");
    this.emailReg = new RegExp("\\w+");

    this.imgPasswordVisible = imgPasswordVisible;
    this.imgPasswordInVisible = imgPasswordInVisible;

    this.labelHeader = configJSON.labelHeader;
    this.labelFirstName = configJSON.labelFirstName;
    this.lastName = configJSON.lastName;
    this.labelEmail = configJSON.labelEmail;
    this.labelPassword = configJSON.labelPassword;
    this.labelRePassword = configJSON.labelRePassword;
    this.labelLegalText = configJSON.labelLegalText;
    this.labelLegalTermCondition = configJSON.labelLegalTermCondition;
    this.labelLegalPrivacyPolicy = configJSON.labelLegalPrivacyPolicy;
    this.btnTextSignUp = configJSON.btnTextSignUp;
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (apiRequestCallId && responseJson) {
         if (apiRequestCallId === this.createAccountApiCallId) {
          if (!responseJson.errors) {
            this.setState({otpEmail:responseJson?.data?.attributes?.email,emailToken:responseJson?.meta?.token})
            await setStorageData("signupOtpEmail",this.state?.otpEmail)
            await setStorageData("clientToken",this.state?.emailToken)
            let payload={
              name:this.state.firstName,
              email:this.state.email,
              password:this.state.newPassword,
              confirmPassword:this.state.confirmPassword,
              privacyPolicy:this.state.isChecked
            }
            this.navigationTwo(payload)
            this.props.navigation.navigate("OtpScreen");
          } 
          else if(responseJson.errors[0]?.account){
            this.setState({emailApiError: responseJson.errors[0]?.account || "Email error", 
              isApiError: true })
            
          }

        this.parseApiCatchErrorResponse(errorReponse);
        }
      
    }
  }
  if (getName(MessageEnum.NavigationPayLoadMessage) === message.id) {
    let data = message.getData(getName(MessageEnum.NavigationPayLoadMessage));
    if(data){
      this.setState({signupInfo:data},()=>{
        this.setState({
          firstName: this.state.signupInfo?.name || "",
          email: this.state.signupInfo?.email || "",
          newPassword: this.state.signupInfo?.password || "",
          confirmPassword: this.state.signupInfo?.confirmPassword || "",
          isChecked: this.state.signupInfo?.privacyPolicy || false,
        })
      })
    }
  }
    // Customizable Area End
  }

  // Customizable Area Start
  goToPrivacyPolicy() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationPrivacyPolicyMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  goToTermsAndCondition() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationTermAndConditionMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  isStringNullOrBlank(str: string) {
    return str === null || str.length === 0;
  }

  isValidEmail(email: string) {
    return this.emailReg.test(email);
  }
  createAccount = () => {
    const header = {
      "Content-Type": configJSON.contentTypeApiAddDetail
    };

    const attrs = {
      first_name : this.state.firstName,
      email: this.state.email,
      password: this.state.newPassword,
      terms_and_condition: this.state.isChecked
    };

    const data = {
      type: "email_account",
      attributes: attrs
    };
    const httpBody = {
      data: data,
    };
   
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.createAccountApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.signupApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }
  getValidations() {
    const headers = {
      "Content-Type": configJSON.validationApiContentType
    };

    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.validationApiCallId = getValidationsMsg.messageId;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.urlGetValidations
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }
  imgEnableRePasswordFieldProps = {
    source: imgPasswordVisible
  };

  btnConfirmPasswordShowHideProps = {
    onPress: () => {
      this.setState({
        enableReTypePasswordField: !this.state.enableReTypePasswordField
      });
      this.txtInputConfirmPasswordProps.secureTextEntry = !this.state
        .enableReTypePasswordField;
      this.imgEnableRePasswordFieldProps.source = this
        .txtInputConfirmPasswordProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    }
  };

  imgEnablePasswordFieldProps = {
    source: imgPasswordVisible
  };

  btnPasswordShowHideProps = {
    onPress: () => {
      this.setState({ enablePasswordField: !this.state.enablePasswordField });
      this.txtInputPasswordProps.secureTextEntry = !this.state
        .enablePasswordField;
      this.imgEnablePasswordFieldProps.source = this.txtInputPasswordProps
        .secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    }
  };

  btnSignUpProps = {
    onPress: () => this.createAccount()
  };

  btnLegalPrivacyPolicyProps = {
    onPress: () => this.goToPrivacyPolicy()
  };

  btnLegalTermsAndConditionProps = {
    onPress: () => this.goToTermsAndCondition()
  };

  txtInputEmailWebPrpos = {
    onChangeText: (text: string) => {
      this.setState({ email: text });
      //@ts-ignore
      this.txtInputEmailPrpos.value = text;
    }
  };

  txtInputEmailMobilePrpos = {
    ...this.txtInputEmailWebPrpos,
    keyboardType: "email-address"
  };

  txtInputEmailPrpos = this.isPlatformWeb()
    ? this.txtInputEmailWebPrpos
    : this.txtInputEmailMobilePrpos;

  txtPhoneNumberWebProps = {
    onChangeText: (text: string) => {
      this.setState({ phone: text });

      //@ts-ignore
      this.txtPhoneNumberProps.value = text;
    }
  };

  txtPhoneNumberMobileProps = {
    ...this.txtPhoneNumberWebProps,
    autoCompleteType: "tel",
    keyboardType: "phone-pad"
  };

  txtPhoneNumberProps = this.isPlatformWeb()
    ? this.txtPhoneNumberWebProps
    : this.txtPhoneNumberMobileProps;

  txtInputLastNamePrpos = {
    onChangeText: (text: string) => {
      this.setState({ lastName: text });

      //@ts-ignore
      this.txtInputLastNamePrpos.value = text;
    }
  };

  txtInputFirstNamePrpos = {
    onChangeText: (text: string) => {
      this.setState({ firstName: text });

      //@ts-ignore
      this.txtInputFirstNamePrpos.value = text;
    }
  };

  txtInputConfirmPasswordProps = {
    onChangeText: (text: string) => {
      this.setState({ reTypePassword: text });

      //@ts-ignore
      this.txtInputConfirmPasswordProps.value = text;
    },
    secureTextEntry: true
  };

  txtInputPasswordProps = {
    onChangeText: (text: string) => {
      this.setState({ password: text });

      //@ts-ignore
      this.txtInputPasswordProps.value = text;
    },
    secureTextEntry: true
  };
  handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ newPassword: e.target.value });
  };
  handleConfirmPasswordChange = (e:React.ChangeEvent<HTMLInputElement>) => {
      this.setState({confirmPassword:e.target.value})
  }

  
  // Validation rules
  hasUppercase = () => /[A-Z]/.test(this.state.newPassword);
  hasLowercase = () => /[a-z]/.test(this.state.newPassword);
  hasSpcCharacter = () => /[!@#$%^&*(),.?":{}|<>]/.test(this.state.newPassword);
  hasNumber = () => /\d/.test(this.state.newPassword);
  handleCheckboxChange = () => {
    this.setState((prevState) => ({
        isChecked: !prevState.isChecked,
    }));
  };
 
  
  
  handlePrivacyNavigation = () => {
    window.open("PrivacyPolicy" , "_blank")
  }
  handleLoginNavigation = () => {
    this.props.navigation.navigate("EmailAccountLoginBlock")
  }
  toggleShowPassword = () => {
    this.setState((prevState) => ({ showPassword: !prevState.showPassword }));
  };
    
  handleTermNavigation = () => {
    window.open("TermsConditions" , "_blank")
  }
  handleSubmit = () => {
    if (this.validateInputs()) {
        this.createAccount();
    }
    
  }
  toggleShowConfirmPassword = () => {
    this.setState((prevState) => ({ showConfirmPassword: !prevState.showConfirmPassword}))
  }

validateInputs = () => {
  const { firstName, email, newPassword, confirmPassword, isChecked } = this.state;
  let isValid = true;
  const emailRegex = configJSON.signupEmailReg;
  this.setState({ emailApiError: "", isApiError: false });
  if (!firstName.trim()) {
      this.setState({ nameError: true });
      isValid = false;
  } else {
      this.setState({ nameError: false });
  }
  if (!emailRegex.test(email)) {
      this.setState({ emailError: true });
      isValid = false;
  } else {
      this.setState({ emailError: false });
  }

  if (!newPassword || newPassword.length < 8 || 
      !this.hasUppercase() || 
      !this.hasLowercase() || 
      !this.hasNumber()) {
      this.setState({ passwordError: true });
      isValid = false;
  } else {
      this.setState({ passwordError: false });
  }

  if (newPassword !== confirmPassword) {
      this.setState({ confirmPasswordError: true });
      isValid = false;
  } else {
      this.setState({ confirmPasswordError: false });
  }

  if (!isChecked) {
      this.setState({ privacyPolicyError: true });
      isValid = false;
  } else {
      this.setState({ privacyPolicyError: false });
  }

  return isValid;
};
navigationTwo = (item:SignupInfo) =>{
  const msgNavigation = new Message(getName(MessageEnum.NavigationMessage));
  window.scrollTo(0, 0);
  msgNavigation.addData(getName(MessageEnum.NavigationTargetMessage), "OtpScreen");
  msgNavigation.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
  const raiseMessage: Message = new Message(getName(MessageEnum.NavigationPayLoadMessage));
  raiseMessage.addData(getName(MessageEnum.NavigationPayLoadMessage), item);
  msgNavigation.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
  this.send(msgNavigation);
}
  // Customizable Area End
}
