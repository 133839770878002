import React from "react";

//Customizable Area Start
import {
    Box,
    TextField,
    Typography,
    Link,
    InputLabel,
    Button,Alert
  } from "@mui/material";
import ForgotPasswordController, { Props } from "./ForgotPasswordController";
import {image,cooksyLogo} from '../src/assets'
//Customizable Area End

export default class ForgotPasswordWeb extends ForgotPasswordController {
  constructor(props: Props) {
    super(props);
    //Customizable Area Start

    //Customizable Area End
  }

  render() {
    //Customizable Area Start
    return (
      <Box
        sx={{
          alignItems: { xs: "center", md: "flex-start" },
          justifyContent: "center",
          display: "flex",
          flexDirection: { xs: "column", md: "row" },
        }}
      >
        <Box
          sx={{
            alignItems: "center",
            margin: { xs: "32px 16px", md: "110px 95px 0px 115px" },
            display: "flex",
            flexDirection: "column",
            width: {md: "auto",xs: "100%"},
            justifyContent: "center",
          }}
        >
          <img style={{width:"182px" , height:"66px"}} src={cooksyLogo} alt="cooksyLogo"/>
          <Box style={webstyles.leftBox}>
            <Box
              sx={{
                width: { xs: "100%", sm: "400px", md: "500px" },marginBottom:"32px"
              }}>
              <Typography sx={webstyles.loginText}>Forgot password?</Typography>
              <Typography sx={webstyles.welcomeText}>
                We'll send you an email to reset your password
              </Typography>
            </Box>
            {this.state?.forgetPwdSuccessRes && <Box>
                <Alert
                  severity="success"
                  icon={false}
                  sx={{
                    fontFamily: "Poppins", 
                  }}
                  style={webstyles.successAlert}
                >
                {this.state.forgetPwdSuccessRes}
                </Alert>                
                </Box>
            }
            {this.state?.invalidForgotEmail && <Box>
                <Alert
                  severity="error"
                  icon={false}
                  sx={{
                    fontFamily: "Poppins", 
                  }}
                  style={webstyles.alert}
                >
                {this.state.invalidForgotEmail}
                </Alert>                
                </Box>
            }
            <Box>
              <Box
                sx={{
                  width: { xs: "100%", sm: "400px", md: "500px" },
                  
                  height: "82px",
                  
                  "& .MuiOutlinedInput-root": {
                    
                    "&:hover fieldset": {
                      
                      borderColor: "#CBD5E1",
                    },
                    "&.Mui-focused fieldset": {
                      borderColor: "#CBD5E1",
                    },
                  },
                  marginBottom:"16px",borderColor: "#CBD5E1",
                }}
              >
                <InputLabel sx={webstyles.emailLabelText}>Email</InputLabel>
                <TextField 
                value={this.state.forgetEmail}
                onChange={(e) => this.setState({forgetEmail:e.target.value}) }
                type="email" placeholder="email@example.com" fullWidth sx={webstyles.forgetEmailText} />
              </Box>
            </Box>
            <Button
              variant="contained"
              fullWidth
              data-testid="getLinkBtn"
              sx={webstyles.loginButton}
              onClick={this.forgetPasswordApi}
            >
            Get a link
            </Button>
            <Box marginTop="24px" textAlign="center">
              <Typography sx={webstyles.signupText}>
                Didn't get an email?{" "}
                <Link sx={webstyles.signupButton} onClick={this.forgetPasswordApi}>
                Resend email
                </Link>
              </Typography>
            </Box>
          </Box>
        </Box>
      {/* Right Section */}
        <Box
          sx={{
            width: { xs: "100%", md: "auto" },
          }}
          style={webstyles.cooksyImgBox}
        >
          <img
            src={image}
            style={webstyles.cooksyImg}
            alt="image"
          />
        </Box>
      </Box>
    );
    //Customizable Area End
  }
}

// Customizable Area Start
const webstyles = {
  leftBox:{
    marginTop:"117px"
  },
  welcomeText:{
    fontFamily:"Poppins !important",
    fontWeight:"400",
    fontSize:16,
    color:"#475467"
  },
  alert:{
    marginTop: "32px",
    marginBottom:"32px", 
    borderRadius: "4px", 
    padding: "8px 16px", 
    borderLeft: "4px solid #DC2626", 
    backgroundColor: "#FEE2E2", 
    color: "#DC2626",
    fontFamily:"Poppins !important",fontWeight:400,fontSize:"12px"
  },
  successAlert:{
    marginTop: "32px",
      marginBottom:"32px", 
      borderRadius: "4px", 
      padding: "8px 16px", 
      borderLeft: "4px solid green", 
      fontFamily:"Poppins !important",fontWeight:400,fontSize:"12px"
  },
  loginText:{
    fontFamily:"Poppins !important",
    fontWeight:"700",
    fontSize:24,
    color:"#000000"
  },
  forgotText:{
    fontFamily:"Poppins !important",
    fontWeight:"700",
    fontSize:14,
    color:"#44041C",
    textAlign: "right",
    width: "100%",
    marginBottom: "32px",
  },
  forgetEmailText:{
    mb: 2, 
    borderRadius: "8px",
    "& .MuiOutlinedInput-root":{
            borderRadius: "8px",
            border:"1px solid #CBD5E1"
        },
        "& .MuiInputBase-input::placeholder": {
            fontFamily: "Poppins",
            color: "#94A3B8", 
            fontWeight: 400, 
            fontSize: "16px",
        },
        "& .MuiInputBase-input":{
          fontFamily: "Poppins",
        },
        "& .MuiOutlinedInput-root fieldset": {
            border: "none", 
        },
        "& .MuiOutlinedInput-notchedOutline": {
            border: "none",
        },
},
  emailLabelText:{
    fontFamily:"Poppins !important",
    fontWeight:"700",
    fontSize:14,
    marginBottom:"4px",
    color:"#334155"
  },
  loginButton:{
    borderRadius:"8px",
    '&:hover': {
            backgroundColor: "#44041C",
    },
    mb: 2,
    width: { xs: "100%", sm: "400px", md: "500px" },
    fontFamily:"Poppins !important",
    fontWeight:"700",
    fontSize:16,
    color:"#FFFFFF",
    backgroundColor:"#44041C",
    height: "56px",
    textTransform:"none" as "none",
    marginBottom:"24px",
    marginTop:"32px",
  },
  signupButton:{
    fontFamily:"Poppins !important",
    fontWeight:"700",
    fontSize:16,
    color:"#44041C",
    textDecorationLine:"underline"
  },
  signupText:{
    fontFamily:"Poppins !important",
    fontWeight:"400",
    fontSize:16,
    color:"#0F172A",
    cursor:"pointer !important"
    },
  cooksyImg : {
    width: "100%",
    maxWidth: "698px",
    borderRadius: 24,
    minHeight: "868px",
    height: "100%"
  },
  cooksyImgBox:{
    padding: "16px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
}
//Customizable Area End
