import React from 'react'
// Customizable Area Start
import { AppBar, Toolbar, Typography, Button, Box } from '@mui/material';
import {cooksyImage} from '../../../blocks/email-account-login/src/assets'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import HeaderController, {
    Props,
  } from "./HeaderController";
export default class Header extends HeaderController {
    constructor(props: Props) {
      super(props);
    }
  showMenu = window.location.pathname === '/LandingPageWeb';
  render() {
  return (
    <AppBar sx={{ ...styles.appBar, backgroundColor: "#ffffff" }}>
    <Toolbar style={styles.toolBar}>
      <img style={styles.logo} src={cooksyImage} alt="logo" />
      {!this.showMenu && <Box style={styles.btnBox}>
        <Button style={styles.homeBtn}>Home</Button>
        <Button style={styles.serviceBtn}>Services
          <KeyboardArrowDownIcon style={styles.dropDown} />
        </Button>
      </Box>}
      <Box style={styles.btnBox}>
        <Typography style={styles.sellerText}>
          Become a Seller
        </Typography>
      <Button  variant="contained" style={styles.signBtn}>
          Sign in
      </Button>
      </Box>
    </Toolbar>
  </AppBar>
  )
}
}
// Customizable Area End
// Customizable Area Start
const styles = {
  appBar:{
    height: "64px",
    position: "static",
    color: "#ffffff", 
    backgroundColor: "#ffffff",
  },
  logo:{
    height:"40px" ,
    width:"110.3px",
  },
  homeBtn:{
    marginRight:"48px",
    textTransform:"none" as "none",
    color:"#475569",
    fontFamily:"Poppins",
    fontWeight:600,
    fontSize:"16px"
  },
  serviceBtn:{
    textTransform:"none" as "none",
    color:"#475569",
    fontFamily:"Poppins",
    fontWeight:600,
    fontSize:"16px"
  },
  dropDown:{
    color:"#57534E",
    width:"30px",
    height:"26px"
  },
  sellerText:{
    padding:"20px 16px 20px 0px",
    color:"#44041C" , 
    fontFamily:"Poppins",
    fontSize:"15px",
    fontWeight:600
  },
  signBtn:{
    margin:"12px 0px 12px 16px",
    fontFamily:"Poppins",
    fontSize:"15px",
    fontWeight:600,
    textTransform:"none" as "none",
    width:"124.75px",
    height: "40px",
    borderRadius:"8px",
    backgroundColor:"#44041C",
    color:"#FFFFFF"
  },
  btnBox:{
    display:"flex",
    alignItems:"center"
  },
  toolBar:{
    display:"flex",
    justifyContent:"space-between",
    padding:"0px 80px"
  }
}
// Customizable Area End