import React from "react";

//Customizable Area Start
import {
    Box,
    TextField,
    Typography,
    Button,Link,Alert
  } from "@mui/material";
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import ForgotPasswordController, { Props } from "./ForgotPasswordController";
import {cooksyLogo,image} from '../src/assets'
//Customizable Area End

export default class ForgotPasswordOtpBlock extends ForgotPasswordController {
  constructor(props: Props) {
    super(props);
    //Customizable Area Start


    //Customizable Area End
  }

  render() {
    return (
      <Box
        sx={{
          justifyContent: "center",
          flexDirection: { xs: "column", md: "row" },
          display: "flex",
          alignItems: { xs: "center", md: "flex-start" },
        }}
      >
        <Box
          sx={{
            alignItems: "center",
            margin: { xs: "32px 16px", md: "110px 95px 0px 115px" },
            flexDirection: "column",
            width: {md: "auto",xs: "100%"},
            justifyContent: "center",
            display: "flex",
            
          }}
        >
          <img style={{width:"182px" , height:"66px"}} src={cooksyLogo} alt="cooksyLogo"/>
          <Box style={webstyles.leftBox}>
            <Box  display="flex" flexDirection="row" marginBottom="32px" onClick={this.handleGoBack}>
            <KeyboardBackspaceIcon style={{width:"16px",cursor:"pointer",height:"12px",border:"3px",color:"#44041C",marginTop:"6px"}}/>
            <Typography sx={{cursor:"pointer",fontFamily:"Poppins",fontsize:"18px",fontWeight:700,color:"#44041C"}} >Back</Typography>
            </Box>
            {this.state?.invalidOtpError && <Box>
                <Alert
                  severity="error"
                  icon={false}
                  sx={{
                    fontFamily: "Poppins", 
                  }}
                  style={webstyles.alert}
                >
                  Please enter valid otp
                </Alert>                
                </Box>
            }
             {this.state?.resendOtpMessage == "Please Check Email" && <Box>
                <Alert
                  severity="error"
                  icon={false}
                  sx={{
                    fontFamily: "Poppins", 
                  }}
                  style={webstyles.alert}
                >
                  {this.state?.resendOtpMessage}
                </Alert>                
                </Box>
            }
            {this.state?.resendOtpMessage && <Box>
                <Alert
                  severity="success"
                  icon={false}
                  style={webstyles.successAlert}
                >
                  {this.state?.resendOtpMessage}
                </Alert>                
                </Box>
            }
            <Box
              sx={{
                width: { xs: "100%", sm: "400px", md: "500px" },marginBottom:"32px"
              }}>
              <Typography sx={webstyles.loginText}>Enter OTP</Typography>
              <Typography sx={webstyles.welcomeText}>
                Enter the code we've sent to {this.state.signupOtpEmail}
              </Typography>
            </Box>
            <Box
          style={{
            display: "flex",
            justifyContent: "space-evenly",
          }}
        >
          {this?.inputs?.map((ref:any, index:any) => (
            <Box
              key={index}
              sx={{
                width: { xs: "56px", sm: "56pxpx", md: "56px" },
                height: "56px",
                marginBottom: "16px",
              }}
            >
              <TextField
                data-testid="otpInput"
                type="text"
                fullWidth
                value={this.state.otpValue[index]}
                inputProps={{ maxLength: 1, style: { textAlign: "center" } }}
                sx={webstyles.otpTextField}
                inputRef={ref}
                onChange={(e) => this.handleInputChange(e, index)}
                onKeyDown={(e) => this.handleKeyDown(e, index)}
              />
            </Box>
          ))}
        </Box>
        <Box marginTop="32px">
                <Typography sx={webstyles.signupText}>
                    Didn't receive it?{" "}
                  <Link data-testid="resendOtp" sx={webstyles.signupButton} onClick={this.resendOtpApi}>
                    Resend OTP
                  </Link>
                </Typography>
              </Box>
            <Button
              variant="contained"
              fullWidth
              data-testid="confirmBtn"
              sx={{
                width: { xs: "100%", sm: "400px", md: "500px" },
                fontFamily:"Poppins"
              }}
              style={webstyles.loginButton}
              onClick={()=>this.confirmOtpApi()}
            >
              Confirm            
            </Button>
          </Box>
        </Box>
      {/* Right Section */}

        <Box
          style={webstyles.cooksyImgBox}
          sx={{
            width: { xs: "100%", md: "auto" },
          }}
          >
          <img
            style={webstyles.cooksyImg}
            src={image}
            alt="image"
          />
        </Box>
      </Box>
    );
  }
}

// Customizable Area Start
const webstyles = {
  alert:{
    marginTop: "32px",
    marginBottom:"32px", 
    borderRadius: "4px", 
    padding: "8px 16px", 
    borderLeft: "4px solid #DC2626", 
    backgroundColor: "#FEE2E2", 
    color: "#DC2626",
    fontFamily:"Poppins !important",fontWeight:400,fontSize:"12px"
  },
  successAlert:{
    marginTop: "32px",
    marginBottom:"32px", 
    borderRadius: "4px", 
    padding: "8px 16px", 
    borderLeft: "4px solid green",
    fontFamily:"Poppins",fontWeight:400,fontSize:"12px"
  },
  otpTextField: {
    mb: 2, borderRadius: "8px",
    "& .MuiOutlinedInput-root": {
      borderRadius: "8px",
      border: "1px solid #CBD5E1"
    },
    "& .MuiInputBase-input::placeholder": {
      fontFamily: "Poppins",
    },
    "& .MuiOutlinedInput-root fieldset": {
      border: "none",
    },
    "& .MuiOutlinedInput-notchedOutline": {
      border: "none",
    },
    "& .MuiInputBase-input": {
      fontFamily: "Poppins",
    },
  },
  signupText:{
    fontFamily:"Poppins !important",
    fontWeight:"400",
    fontSize:16,
    color:"#0F172A",
    },
  cooksyImg : {
    width: "100%",
    maxWidth: "698px",
    borderRadius: 24,
    minHeight: "868px",
    height: "100%"
  },
  cooksyImgBox:{
    padding: "16px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  leftBox:{
    marginTop:"117px"
  },
  welcomeText:{
    fontFamily:"Poppins !important",
    fontWeight:"400",
    fontSize:16,
    color:"#475467"
  },
  loginText:{
    fontFamily:"Poppins !important",
    fontWeight:"700",
    fontSize:24,
    color:"#000000"
  },

  signupButton:{
    cursor:"pointer !important",
    fontFamily:"Poppins !important",
    fontWeight:"700",
    fontSize:16,
    color:"#44041C",
    textDecorationLine:"underline"
  },
  forgotText:{
    fontFamily:"Poppins !important",
    fontWeight:"700",
    fontSize:14,
    color:"#44041C",
    textAlign: "right",
    width: "100%",
    marginBottom: "32px",
  },
  labelText:{
    fontFamily:"Poppins !important",
    fontWeight:"700",
    fontSize:14,
    color:"#334155"
  },
  loginButton:{
    fontFamily:"Poppins !important",
    fontWeight:"700",
    fontSize:16,
    '&:hover': {
            backgroundColor: "#44041C",
    },
    color:"#FFFFFF",
    backgroundColor:"#44041C",
    height: "56px",
    textTransform:"none" as "none",
    marginTop:"32px"
  },
}
//Customizable Area End