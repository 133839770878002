import React from "react";

// Customizable Area Start
import {
  Box,
  Typography,CircularProgress
} from "@mui/material";
import moment from "moment";
import Header from '../../../blocks/landingpage/src/Header.web'
// Customizable Area End

import TermsConditionsController, {
  Props,
} from "./TermsConditionsController";

export default class PrivacyPolicy extends TermsConditionsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start

    return (
      <>
      {this.state.createdDate ? ( <>
        <Header navigation={undefined} id={""} />
        <Box style={styles.box}>
         <Typography style={styles.date}>
           Current as of {moment(this.state.createdDate).format("DD MMM YYYY")}
          </Typography>
          <Typography  gutterBottom style={styles.Terms}>
            {this.state.policyTitle}
          </Typography>
          <Typography style={styles.typo1}>
           {this.state.policyDescription}
          </Typography>
          </Box>
      </>) : 
      <Box
    sx={{
        alignItems: "center",
        display: "flex",
        justifyContent: "center",
        height: "100vh",
      }}
    >
      <CircularProgress />
      </Box>
      }
      </>
    )
    // Customizable Area End
  }
}

// Customizable Area Start
const styles = {
  typoBox:{
    marginBottom:"24px",
    fontWeight:600,
    fontSize:"30px",
    fontFamily:"Poppins",
    textAlign:"start" as "start"
   },
   typo4:{
    textAlign:"start" as "start",
    fontWeight: 400, 
    fontSize: "18px", 
    fontFamily: "Poppins",
    color: "#475467", 
   },
   paragraph:{
    color: "#475467", 
    fontWeight: 400, 
    fontSize: "18px", 
    textAlign:"start" as "start",
    fontFamily: "Poppins",
    marginLeft:"50px"
   },
  typo1:{
   marginTop:"68px",
   textAlign:"start" as "start",
   color: "#475467", 
   fontWeight: 400, 
   fontSize: "18px", 
   fontFamily: "Poppins"
  },
  typo5:{
    marginBottom:"24px",
    textAlign:"start" as "start",
    fontWeight:600,
    fontSize:"30px",
    fontFamily:"Poppins"
   },

   typo6:{
    textAlign:"start" as "start",
    fontWeight: 400, 
    fontSize: "18px", 
    fontFamily: "Poppins",
    color: "#475467", 
   },

  typo2:{
   textAlign:"start" as "start", 
   color: "#475467", 
   fontWeight: 400, 
   fontSize: "18px", 
   fontFamily: "Poppins"
  },
  box:{
    margin:"96px auto 0px auto",
    textAlign: "center" as "center",
    width:"90%" , 
    maxWidth:"720px"
   },
   date:{
     fontFamily:"Poppins",
     fontWeight:600,
     fontSize:"16px",
     color:"#44041C"
    },
   Terms:{
    color: "#101828", 
    fontWeight: 600, 
    fontSize: "48px", 
    fontFamily: "Poppins"
   },
}
// Customizable Area End
