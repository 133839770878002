import React from "react";

// Customizable Area Start

import {
  Box,
  TextField,
  Button,
  Typography,
  IconButton,
  InputAdornment,
  InputLabel,
  Alert
} from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import { Visibility } from "@mui/icons-material";
import {cooksyImage,rightImage} from '../src/assets'
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
// Customizable Area End

import EmailAccountLoginController, {
  Props,
} from "./EmailAccountLoginController";

export default class EmailAccountLoginBlock extends EmailAccountLoginController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
 
  // Customizable Area End
  render() {
    return (
      // Customizable Area Start
      // Required for all blocks
      <>
        <Box
          sx={{
            alignItems: { xs: "center", md: "flex-start" },
            display: "flex",
            flexDirection: { xs: "column", md: "row" },
            justifyContent: "center",
          }}
        >
          {/* Left Section  */}
          <Box
            sx={{
              flexDirection: "column",
              margin: { xs: "32px 16px", md: "225px 95px 0px 115px" },
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: { xs: "100%", md: "auto" },
            }}
          >
            <img style={{width:"182px" , height:"66px"}} src={cooksyImage} alt="cooksyLogo"/>
            <Box marginTop="49px">
              <Box
                sx={{
                  width: { xs: "100%", sm: "400px", md: "500px" },marginBottom:"32px"
                }}>
                <Typography sx={styles.loginText}>Log in</Typography>
                <Typography sx={styles.welcomeText}>
                  Welcome back! Please enter your details.
                </Typography>
              </Box>
              {this.state.loginSuccessAlert && <Box>
                <Alert
                  severity="success"
                  icon={false}
                  style={styles.successAlert}
                >
                  Login Successfully
                </Alert>                
                </Box>}
                {this.state.loginErrorMsg && <Box>
                <Alert
                  severity="error"
                  icon={false}
                  style={styles.alert}
                >
                  {this.state.loginErrorMsg} 
                </Alert>                
                </Box>}
             {this.state.emailError && <Box>
                <Alert
                  severity="error"
                  icon={false}
                  style={styles.alert}
                  action={
                    <IconButton
                      data-testid="emailError"
                      aria-label="close"
                      color="inherit"
                      size="small"
                      onClick={() => this.setState({emailError:false})}
                      sx={{ color: "#DC2626" }} 
                    >
                      <CloseIcon fontSize="small" />
                    </IconButton>
                  }
                >
                  Invalid email 
                </Alert>                
                </Box>}
                {this.state.pwdError && <Box>
                <Alert
                  severity="error"
                  icon={false}
                  style={styles.alert}
                  action={
                    <IconButton
                      data-testid="pwdError"
                      aria-label="close"
                      color="inherit"
                      size="small"
                      onClick={() => this.setState({pwdError:false})}
                      sx={{ color: "#DC2626" }} 
                    >
                      <CloseIcon fontSize="small" />
                    </IconButton>
                  }
                >
                  Incorrect Password 
                </Alert>                
                </Box>}
              <Box sx={{ height: "218px" }}>
                <Box
                  sx={{
                    width: {
                      xs: "100%", sm: "400px", md: "500px",
                      borderColor: "#CBD5E1",
                      "& .MuiOutlinedInput-root": {
                        "&:hover fieldset": {
                          borderColor: "#CBD5E1",
                        },
                        "&.Mui-focused fieldset": {
                          borderColor: "#CBD5E1",
                        },
                      },
                    },
                    height: "82px", marginBottom: "16px"
                  }}
                >
                  <InputLabel sx={styles.inputLabelText}>Email</InputLabel>
                  <TextField 
                   value={this.state.email}
                   data-testid="emailField"
                   onChange={(e) => this.setState({email:e.target.value})}
                   type="email" placeholder="email@example.com" fullWidth  
                   sx={styles.emailField}  />
                </Box>
                <Box
                  sx={{
                    width: { xs: "100%", sm: "400px", md: "500px" },
                    height: "82px",
                    borderColor: "#CBD5E1",
                    mb: 2,
                    "& .MuiOutlinedInput-root": {
                      "&:hover fieldset": {
                        borderColor: "#CBD5E1",
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: "#CBD5E1",
                      },
                    },
                  }}
                >
                  <InputLabel sx={styles.inputLabelText}>Password</InputLabel>
                  <TextField
                    type={this.state.showPassword ? "text" : "password"}
                    fullWidth
                    value={this.state.password}
                    onChange={this.handleLoginPasswordChange}
                    sx={styles.pwdField}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton onClick={this.toggleShowPassword}>
                          {this.state.showPassword ? <Visibility style={{color:"#94A3B8"}} /> : <VisibilityOffOutlinedIcon style={{color:"#94A3B8"}} />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Box>
                <Box>
                  <Typography
                    sx={styles.forgotText}
                    onClick={this.handleNavigation}
                  >
                    Forgot password
                  </Typography>
                </Box>
              </Box>
              <Button
                variant="contained"
                data-testid="loginBtn"
                fullWidth
                sx={styles.loginButton}
                onClick={() => this.doEmailLogIn()}
              >
                Log in
              </Button>
              <Box sx={styles.loginAcc}>
                <Typography sx={styles.signupText}>
                  Don't have an account?{" "}
                  <span style={styles.signupButton} onClick={this.handleSignupNavigation} >
                  Sign up
                  </span>
                </Typography>
              </Box>
            </Box>
          </Box>
        {/* Right Section */}
          <Box
            style={styles.cooksyImgBox}
            sx={{
              width: { xs: "100%", md: "auto" },
            }}
          >
            <img
              src={rightImage}
              alt="images"
              style={styles.cooksyImg}
            />
          </Box>
        </Box>

      </>
      // Customizable Area End
    );
  }

}
//Customizable Area Start
const styles = {
  successAlert:{
    marginTop: "32px",
    marginBottom:"32px", 
    borderRadius: "4px", 
    padding: "8px 16px", 
    borderLeft: "4px solid green",
    fontFamily:"Poppins",fontWeight:400,fontSize:"12px"
  },
  alert:{
    marginTop: "32px",
    marginBottom:"32px", 
    borderRadius: "4px", 
    padding: "8px 16px", 
    borderLeft: "4px solid #DC2626", 
    backgroundColor: "#FEE2E2", 
    color: "#DC2626",
    fontFamily:"Poppins",fontWeight:400,fontSize:"12px"
  },
  pwdField:{
    mb: 2,
    borderRadius: "8px",
    "& .MuiOutlinedInput-root":{
            borderRadius: "8px",
            border:"1px solid #CBD5E1"
        },
        "& .MuiInputBase-input":{
          fontFamily: "Poppins",
        },
        "& .MuiInputBase-input::placeholder": {
            fontFamily: "Poppins",
            color: "#94A3B8", 
            fontWeight: 400, 
            fontSize: "16px",
        },
        "& .MuiOutlinedInput-root fieldset": {
            border: "none", 
        },
        "& .MuiOutlinedInput-notchedOutline": {
            border: "none",
        },
},
  welcomeText:{
    fontFamily:"Poppins !important",
    fontWeight:"400",
    fontSize:16,
    color:"#475467"
  },
  emailField:{
    borderRadius: "8px",
    "& .MuiInputBase-input":{
          fontFamily: "Poppins",
    },
    "& .MuiOutlinedInput-root":{
            borderRadius: "8px",
            border:"1px solid #CBD5E1"
        },
        "& .MuiInputBase-input::placeholder": {
            fontFamily: "Poppins",
            color: "#94A3B8", 
            fontWeight: 400, 
            fontSize: "16px",
        },
        "& .MuiOutlinedInput-root fieldset": {
            border: "none", 
        },
        "& .MuiOutlinedInput-notchedOutline": {
            border: "none",
        },
    mb: 2, 
},
  inputLabelText:{
    fontFamily:"Poppins !important",
    fontSize:14,
    color:"#334155",
    marginBottom:"4px",
    fontWeight:"700",
  },
  loginText:{
    fontFamily: 'Poppins !important' ,
    fontWeight:"700",
    fontSize:24,
    color:"#000000",
  },
  forgotText:{
    fontFamily:"Poppins !important",
    fontWeight:"700",
    fontSize:14,
    color:"#44041C",
    textAlign: "right" as "right",
    width: "100%",
    marginBottom: "32px",
    cursor:"pointer !important"
  },
  loginButton:{
    mb: 2,
    width: { xs: "100%", sm: "400px", md: "500px" },
    borderRadius:"8px",
    fontFamily:"Poppins !important",
    fontWeight:"700",
    fontSize:16,
    color:"#FFFFFF",
    backgroundColor:"#44041C",
    height: "56px",
    textTransform:"none" as "none",
    marginBottom:"24px",
    marginTop:"32px",
    '&:hover': {
            backgroundColor: "#44041C",
    },
  },
  cooksyImgBox:{
    padding: "16px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  cooksyImg : {
    width: "100%",
    maxWidth: "698px",
    borderRadius: 24,
    minHeight: "868px",
    height: "100%"
  },
  signupText:{
    fontFamily:"Poppins !important",
    fontWeight:"400",
    fontSize:16,
    color:"#0F172A",
    },
  loginAcc:{
    marginTop:"24px" ,
    textAlign:"center" as "center",
    cursor:"pointer !important"
  },
  signupButton:{
    fontFamily:"Poppins !important",
    fontWeight:"700",
    fontSize:16,
    color:"#44041C",
    textDecorationLine:"underline",
    cursor: "pointer !important"
  },
  main: {
    display: "flex",
    alignItems: "center"
  },
  body: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "10px",
    width: "50%",
  },
  textFont: {
    fontFamily: "Helvetica"
  },
  formWrapper: {
    display: "flex",
    width: "100%",
    paddingTop: "20px"
  },
  inputWrapper: {
    flex: 1,
    padding: "20px"
  },
  removeBackground: {
    backgroundColor: "transparent"
  },
  checkBoxText: {
    fontSize: 18,
    color: "#6200EE",
    fontWeight: "bold",
  },
  buttonsWrapper: {
    flex: 1,
    padding: "10px",
  },
  OrWrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "10px",
  }
}
// Customizable Area End
