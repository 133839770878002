import React from "react";

//Customizable Area Start
import {
  Box,
  TextField,
  Button,
  Typography,
  IconButton,
  InputAdornment,
  InputLabel,Alert
} from "@mui/material";
import ResetPasswordController, { Props } from "./ResetPasswordController";
import { Visibility } from "@mui/icons-material";
import {image,cooksyLogo } from '../src/assets';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
//Customizable Area End

export default class ResetPassword extends ResetPasswordController {
  constructor(props: Props) {
    super(props);
    //Customizable Area Start
    //Customizable Area End
  }

  render() {
    const { showPwd, showConfirmPwd, confirmPwd, password } = this.state;
    return (
      <>
        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", md: "row" },
            alignItems: { xs: "center", md: "flex-start" },
            justifyContent: "center",
          }}
        >
          {/* Left Side Box */}
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              margin: { xs: "32px 16px", md: "110px 95px 0px 115px" },
              width: { xs: "100%", md: "auto" },
            }}
          >
            <img style={{ width: "182px", height: "66px" }} src={cooksyLogo} alt="cooksyLogo" />
            <Box style={webStyle.box}>
              <Box sx={{ width: { md: "500px", xs: "100%", sm: "400px" }, marginBottom: "32px" }}>
                <Typography sx={webStyle.loginText}>Reset password</Typography>
                <Typography sx={webStyle.welcomeText}>
                  Set a new password for your account
                </Typography>
              </Box>
              {this.state?.differentPwd && <Box>
                <Alert
                  severity="error"
                  icon={false}
                  sx={{
                    fontFamily: "Poppins", 
                  }}
                  style={webStyle.alert}
                >
                  {this.state.differentPwd}
                </Alert>                
                </Box>
            }
            {this.state?.resetPwdSuccessRes && <Box>
                <Alert
                  severity="success"
                  icon={false}
                  sx={{
                    fontFamily: "Poppins", 
                  }}
                  style={webStyle.successAlert}
                >
                  {this.state.resetPwdSuccessRes}
                </Alert>                
                </Box>
            }
              <Box>
                <Box
                  sx={{
                    width: { xs: "100%", sm: "400px", md: "500px" },
                    height: "82px", borderColor: "#CBD5E1",
                    "& .MuiOutlinedInput-root": {
                      borderRadius: "8px",
                      border: "1px solid #CBD5E1"
                    },
                    "& .MuiOutlinedInput-root fieldset": {
                      border: "none",
                    },
                    "& .MuiInputBase-input::placeholder": {
                      fontFamily: "Poppins",
                      color: "#94A3B8",
                      fontWeight: 400,
                      fontSize: "16px",
                    },
                    "& .MuiOutlinedInput-notchedOutline": {
                      border: "none",
                    },

                  }}
                >
                  <InputLabel sx={webStyle.resetLabelText}>Password</InputLabel>
                  <TextField
                    value={password}
                    onChange={this.handlePwdChange}
                    type={showPwd ? "text" : "password"}
                    fullWidth 
                    sx={webStyle.pwdText}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton onClick={this.toggleShowPwd}>
                            {showPwd ? <Visibility style={{ color: "#94A3B8" }} /> :
                              <VisibilityOffOutlinedIcon style={{ color: "#94A3B8" }}
                              />
                            }
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Box>
                <Box marginBottom="16px">
                  <Typography
                    style={{
                      color: "#0F172A",
                      fontSize: "12px",
                      display: "flex",
                      alignItems: "center",
                      marginBottom: "8px",
                      fontWeight: 400,
                      fontFamily:"Poppins"
                    }}
                  >
                    {this.hasUppercase() && (
                      <Box sx={webStyle.passwordValidBoxTop}>
                        <CheckCircleIcon style={webStyle.greenCheck} />
                      <Typography sx={webStyle.pwdConditions}>At least one capital letter</Typography>
                      </Box>
                    )}
                  </Typography>
                  <Typography
                    style={{
                      color: "#0F172A",
                      fontSize: "12px",
                      display: "flex",
                      alignItems: "center",
                      marginBottom: "8px",
                      fontWeight: 400,
                      fontFamily:"Poppins"
                    }}
                  >
                    {this.hasLowercase() && (
                      <Box sx={webStyle.passwordValidBox}>
                        <CheckCircleIcon style={webStyle.greenCheck} />
                        <Typography sx={webStyle.pwdConditions}>At least one lowercase letter</Typography>
                      </Box>
                    )}
                  </Typography>
                  <Typography
                    style={{
                      color: "#0F172A",
                      fontSize: "12px",
                      display: "flex",
                      alignItems: "center",
                      marginBottom: "8px",
                      fontWeight: 400,
                      fontFamily:"Poppins"
                    }}
                  >
                    {this.hasNumber() && (
                      <Box sx={webStyle.passwordValidBox}>
                        <CheckCircleIcon style={webStyle.greenCheck} />
                        <Typography sx={webStyle.pwdConditions}>At least one number</Typography>
                      </Box>
                    )}
                  
                  </Typography>
                  <Typography
                    style={{
                      color: "#0F172A",
                      fontSize: "12px",
                      display: "flex",
                      alignItems: "center",
                      marginBottom: "8px",
                      fontWeight: 400,
                      fontFamily:"Poppins"
                    }}
                  >
                    {this.hasSpecialCharacter() && (
                      <Box sx={webStyle.passwordValidBox}>
                        <CheckCircleIcon style={webStyle.greenCheck} />
                        <Typography sx={webStyle.pwdConditions}>At least one special character</Typography>
                      </Box>
                    )}
                    
                  </Typography>
                </Box>
                <Box
                  sx={{
                    width: { xs: "100%", sm: "400px", md: "500px" },
                    height: "82px", marginBottom: "16px",borderColor: "#CBD5E1",
                    "& .MuiInputBase-input::placeholder": {
                      fontFamily: "Poppins",
                      color: "#94A3B8",
                      fontWeight: 400,
                      fontSize: "16px",
                    },
                    "& .MuiOutlinedInput-notchedOutline": {
                      border: "none",
                    },
                    "& .MuiOutlinedInput-root": {
                      borderRadius: "8px",

                      border: "1px solid #CBD5E1"
                    },
                    "& .MuiOutlinedInput-root fieldset": {
                      border: "none",
                    },
            
                  }}
                >
                  <InputLabel sx={webStyle.resetLabelText}>Confirm password</InputLabel>
                  <TextField value={confirmPwd}
                    onChange={this.handleConfirmPwdChange}
                    type={showConfirmPwd ? "text" : "password"}
                    fullWidth sx={webStyle.pwdText}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton onClick={this.toggleShowConfirmPwd}>
                            {showConfirmPwd ? <Visibility style={{ color: "#94A3B8" }} /> :

                              <VisibilityOffOutlinedIcon style={{ color: "#94A3B8" }}
                              />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Box>
              </Box>
              <Button
                variant="contained"
                fullWidth
                data-testid="submitBtn"
                sx={webStyle.submitBtn}
                onClick={this.ResetPasswordApi}
              >
                Submit
              </Button>
            </Box>
          </Box>
          {/* Right Section */}
          <Box
            sx={{
              width: { xs: "100%", md: "auto" },
            }}
            style={webStyle.cooksyImgBox}
          >
            <img
              style={webStyle.cooksyImg}
              alt="image"
              src={image}
            />
          </Box>
        </Box>

      </>
    );
  }
}

// Customizable Area Start
const webStyle = {
  successAlert:{
    width:"460px",
    marginTop: "32px",
    marginBottom:"32px", 
    borderRadius: "4px", 
    padding: "8px 16px", 
    borderLeft: "4px solid green",
    fontFamily:"Poppins !important",fontWeight:400,fontSize:"12px"
  },
  alert:{
    width:"460px",
    marginTop: "32px",
    marginBottom:"32px", 
    borderRadius: "4px", 
    padding: "8px 16px", 
    borderLeft: "4px solid #DC2626", 
    backgroundColor: "#FEE2E2", 
    color: "#DC2626",
    fontFamily:"Poppins !important",fontWeight:400,fontSize:"12px"
  },
  box: {
    marginTop: "40px"
  },
  loginText: {
    fontFamily: "Poppins !important",
    fontWeight: "700",
    fontSize: 24,
    color: "#000000"
  },
  welcomeText: {
    fontFamily: "Poppins !important",
    fontWeight: "400",
    fontSize: 16,
    color: "#475467"
  },
  pwdText:{
    mb: 2,
    borderRadius: "8px",
    "& .MuiOutlinedInput-root":{
      borderRadius: "8px",
      border:"1px solid #CBD5E1"
    },
    "& .MuiInputBase-input":{
          fontFamily: "Poppins",
    }
  },
  pwdConditions:{
    fontSize: "12px",
    color: "#0F172A",
    fontFamily: "Poppins !important",
    fontWeight: "400",
},
passwordValidBox:{
  display:"flex",
  flexDirection:"row"
},
passwordValidBoxTop:{
  display:"flex",
  flexDirection:"row",
  marginTop:"8px"
},
  greenCheck:{
    marginRight: "3px",
    marginTop:"2px",
    color: "#34D399",
    width: "12px", 
    height: "12px", 
  },
  resetLabelText: {
    fontFamily: "Poppins !important",
    fontWeight: "700",
    marginBottom:"4px",
    fontSize: 14,
    color: "#334155"
  },
  forgotText: {
    fontFamily: "Poppins !important",
    fontWeight: "700",
    fontSize: 14,
    color: "#44041C",
    textAlign: "right",
    width: "100%",
    marginBottom: "32px",
  },
  resetText: {
    textTransform: "none" as "none",
    fontFamily: "Poppins !important",
    fontWeight: "700",
    fontSize: "16px",
    color: "#FFFFFF",
    backgroundColor: "#44041C",
    height: "56px",
    marginBottom: "24px",
    marginTop: "32px"
  },
  submitBtn: {
    mb: 2,
    width: {
      xs: "100%", sm: "400px", md: "500px",
    }, fontFamily: "Poppins !important",
    fontWeight: "700",
    fontSize: 16,
    color: "#FFFFFF",
    backgroundColor: "#44041C",
    height: "56px",
    textTransform: "none",
    marginBottom: "24px",
    marginTop: "32px",
    borderRadius: "8px",
    "&:hover": {
      backgroundColor: "#44041C",
    },
  },
  signupText: {
    fontFamily: "Poppins !important",
    fontWeight: "400",
    fontSize: 16,
    color: "#0F172A",
    cursor:"pointer !important"
  },
  signupButton: {
    fontFamily: "Poppins !important",
    fontWeight: "700",
    fontSize: 16,
    color: "#44041C",
    textDecorationLine: "underline"
  },
  cooksyImgBox: {
    padding: "16px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  cooksyImg: {
    width: "100%",
    maxWidth: "698px",
    borderRadius: 24,
    minHeight: "868px",
    height: "100%"
  }
}
//Customizable Area End