import React from "react";

// Customizable Area Start
import {
  Box,
  Typography,CircularProgress
} from "@mui/material"
import Header from '../../../blocks/landingpage/src/Header.web'
import moment from "moment";
// Customizable Area End

import TermsConditionsController, {
  Props,
} from "./TermsConditionsController";

export default class TermsConditions extends TermsConditionsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
      <>
      {this.state.termsDate ? (
        <>
        <Header navigation={undefined} id={""} />
        <Box style={webStyle.box}>
          <Typography style={webStyle.date}>
          Current as of {moment(this.state.termsDate).format("DD MMM YYYY")}
          </Typography>
          <Typography  gutterBottom style={webStyle.Terms}>
            {this.state.termsTitle}
          </Typography>
          <Typography style={webStyle.typo1}>
          {this.state.termsDescription}
          </Typography>
        </Box>
      </>) : 
      <Box
      sx={{
        height: "100vh",
        alignItems: "center",
        display: "flex",
        justifyContent: "center",
      }}
    >
      <CircularProgress  />
    </Box>
    }
      </>
    )
    // Customizable Area End
  }
}

// Customizable Area Start
const webStyle = {
   box:{
    margin:"96px auto 0px auto",
    textAlign: "center" as "center",
    width:"90%" , 
    maxWidth:"720px"
   },

   paragraph:{
    fontSize: "18px", 
    fontFamily: "Poppins",
    marginLeft:"50px",
    textAlign:"start" as "start",
    color: "#475467", 
    fontWeight: 400, 
   },
   date:{
    fontFamily:"Poppins",
    fontWeight:600,
    fontSize:"16px",
    color:"#44041C"
   },
   Terms:{
    color: "#101828", 
    fontWeight: 600, 
    fontSize: "48px", 
    fontFamily: "Poppins"
   },
   typo4:{
    textAlign:"start" as "start",
    fontWeight: 400, 
    fontSize: "18px", 
    fontFamily: "Poppins",
    color: "#475467", 
   },
   typo1:{
    marginTop:"68px",
    textAlign:"start" as "start",
    color: "#475467", 
    fontWeight: 400, 
    fontSize: "18px", 
    fontFamily: "Poppins"
   },
   typo2:{
    textAlign:"start" as "start", 
    color: "#475467", 
    fontWeight: 400, 
    fontSize: "18px", 
    fontFamily: "Poppins"
   },
   typo6:{
    textAlign:"start" as "start",
    color: "#475467", 
    fontWeight: 400, 
    fontSize: "18px", 
    fontFamily: "Poppins"
   },
   typo5:{
    marginBottom:"24px",
    textAlign:"start" as "start",
    fontWeight:600,
    fontSize:"30px",
    fontFamily:"Poppins"
   },
   typo3:{
    fontFamily:"Poppins",
    textAlign:"start" as "start",
    marginBottom:"24px",
    fontWeight:600,
    fontSize:"30px",
   },
}
// Customizable Area End
