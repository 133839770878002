import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import { runEngine } from "../../../framework/src/RunEngine";
import { Message } from "../../../framework/src/Message";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import { getStorageData } from "../../../framework/src/Utilities";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  // Customizable Area Start
  
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  accountType: string;
  signupOtpEmail:string;
  loginToken:string;
  forgetEmail:string;
  invalidOtpError:string;
  activationMessage:string;
  enablePasswordField: boolean;
  btnConfirmPasswordShowHide: boolean;
  signupOtpToken:string;
  resetPwdSuccessRes:string;
  differentPwd:string;
  showConfirmPwd:boolean;
  showPwd:boolean;
  password:string;
  confirmPwd:string;
  otpValue:any
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  navigation: any;

  // Customizable Area End
}

// Customizable Area Start
// Customizable Area End

export default class ResetPasswordController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start

  
  resetPasswordAPICallId:string = "";
  requestEmailOtpCallId: any;
  requestPhoneOtpCallId: any;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.RestAPIResponceMessage),

      getName(MessageEnum.CountryCodeMessage),
      // Customizable Area End
    ];

    this.receive = this.receive.bind(this);

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start


    this.state = {
      loginToken:"",
      forgetEmail:"",
      differentPwd:"",
      signupOtpToken:"",
      invalidOtpError:"",
      activationMessage:"",
      resetPwdSuccessRes:"",
      signupOtpEmail:"",
      otpValue: Array(6).fill(""),
      password:"",
      accountType: "sms",
      enablePasswordField: true,
      btnConfirmPasswordShowHide: true,
      showConfirmPwd:false,
      showPwd:false,
      confirmPwd:""
    };
    // Customizable Area End
  }
// Customizable Area Start
  async componentDidMount() {
    const loginApiToken = await getStorageData("loginToken")
    this.setState({loginToken:loginApiToken})
  }


  ResetPasswordApi = () => {
    const header = {
      "Content-Type": configJSON.forgotPasswordAPiContentType
    };

    const data = {
        token: this.state.loginToken,
        new_password: this.state.password,
        password_confirmation: this.state.confirmPwd 
    };
    

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.resetPasswordAPICallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.resetPasswordApiEndPoint
    );


    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(data)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  async receive(from: string, message: Message)
   {
    
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
    if (apiRequestCallId === this.resetPasswordAPICallId) {
      if(responseJson.message){
        this.setState({resetPwdSuccessRes : responseJson.message,differentPwd: ""})
        this.props.navigation.navigate("EmailAccountLoginBlock");
      }
      else{
        this.setState({differentPwd:responseJson.error,resetPwdSuccessRes:""})
      }
  }
    } 
  }

 
  // Customizable Area Start
  
  handlePwdChange = (e:React.ChangeEvent<HTMLInputElement>) => {
    this.setState({password:e.target.value})
  }
  toggleShowPwd = () => {
    this.setState((prevState) => ({ showPwd: !prevState.showPwd}))
  }  
  toggleShowConfirmPwd = () => 
  {
    this.setState((prevState) => ({ showConfirmPwd: !prevState.showConfirmPwd}))
  }
  handleConfirmPwdChange = (e:React.ChangeEvent<HTMLInputElement>) => {
    this.setState({confirmPwd:e.target.value})
  }
  // Validation rules
   hasUppercase = () => /[A-Z]/.test(this.state.password);
   hasLowercase = () => /[a-z]/.test(this.state.password);
   hasNumber = () => /\d/.test(this.state.password);
   hasSpecialCharacter = () => /[!@#$%^&*(),.?":{}|<>]/.test(this.state.password);
  // Customizable Area End
}
// Customizable Area End