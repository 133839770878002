import React from "react";

// Customizable Area Start
import {
    IconButton,
    TextField,
    Button,
    InputLabel,
    Typography,
    InputAdornment,
    Box,
    Alert
} from "@mui/material";
import EmailAccountRegistrationController, {
    Props
} from "./EmailAccountRegistrationController";
import CloseIcon from '@mui/icons-material/Close';
import { Visibility } from "@mui/icons-material";
import { cooksyImg,sideImage } from './assets'
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
// Customizable Area End

export default class EmailAccountRegistrationBlock extends EmailAccountRegistrationController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start

    // Customizable Area End
    render() {
        const { nameError,
            emailError,isApiError,
            passwordError,
            confirmPasswordError,
            privacyPolicyError,firstName, email,isChecked, confirmPassword, showPassword, newPassword, showConfirmPassword } = this.state;
        return (
            // Customizable Area Start
            // Required for all blocks

            <Box
             sx={webstyles.topBox}>
                {/* Left Section  */}
                <Box
                    sx={webstyles.leftBox}
                >
                    <img style={webstyles.cooksyLogo} src={cooksyImg} alt="cooksyLogo" />
                    <Box marginTop="51px">
                        <Box
                            sx={{
                                width: { xs: "100%", sm: "400px", md: "500px" }, marginBottom: "32px"
                            }}>
                            <Typography sx={webstyles.loginText}>Sign up</Typography>
                            <Typography sx={webstyles.welcomeText}>
                                Create an account to start using our services.
                            </Typography>
                        </Box>
                {nameError && <Box>
                <Alert
                  severity="error"
                  icon={false}
                  sx={{
                    fontFamily: "Poppins", 
                  }}
                  style={webstyles.alert}
                  action={
                    <IconButton
                      aria-label="close"
                      color="inherit"
                      size="small"
                      onClick={() => this.setState({nameError:false})}
                      sx={{ color: "#DC2626" }} 
                    >
                      <CloseIcon fontSize="small" />
                    </IconButton>
                  }
                >
                  Name Can't be Blank 
                </Alert>                
                </Box>}
                {(emailError && !isApiError) && <Box>
                <Alert
                  severity="error"
                  icon={false}
                  style={webstyles.alert}
                  sx={{
                    fontFamily: "Poppins", 
                  }}
                  action={
                    <IconButton
                      aria-label="close"
                      color="inherit"
                      size="small"
                      onClick={() => this.setState({emailError:false})}
                      sx={{ color: "#DC2626" }} 
                    >
                      <CloseIcon fontSize="small" />
                    </IconButton>
                  }
                >
                  Email can't be Blank
                </Alert>                
                </Box>}
                        {this.state?.emailApiError && (
                            <Box>
                                <Alert
                                    severity="error"
                                    icon={false}
                                    style={webstyles.alert}
                                    sx={{ fontFamily: "Poppins" }}
                                    action={
                                        <IconButton
                                            aria-label="close"
                                            color="inherit"
                                            size="small"
                                            onClick={() => this.setState({ emailApiError: "" })}
                                            sx={{ color: "#DC2626" }}
                                        >
                                            <CloseIcon fontSize="small" />
                                        </IconButton>
                                    }
                                >
                                    {this.state.emailApiError}
                                </Alert>
                            </Box>
                        )}
                {passwordError  && <Box>
                <Alert
                  severity="error"
                  icon={false}
                  sx={{
                    fontFamily: "Poppins", 
                  }}
                  style={webstyles.alert}
                  action={
                    <IconButton
                      aria-label="close"
                      color="inherit"
                      size="small"
                      onClick={() => this.setState({passwordError:false})}
                      sx={{ color: "#DC2626" }} 
                    >
                      <CloseIcon fontSize="small" />
                    </IconButton>
                  }
                >
                  Password must be at least 8 characters long and include a mix of uppercase, lowercase,numbers and special character.
                </Alert>                
                </Box>}
                {confirmPasswordError   && <Box>
                <Alert
                  severity="error"
                  icon={false}
                  sx={{
                    fontFamily: "Poppins", 
                  }}
                  style={webstyles.alert}
                  action={
                    <IconButton
                      aria-label="close"
                      color="inherit"
                      size="small"
                      onClick={() => this.setState({confirmPasswordError:false})}
                      sx={{ color: "#DC2626" }} 
                    >
                      <CloseIcon fontSize="small" />
                    </IconButton>
                  }
                >
               Passwords do not match.
                </Alert>                
                </Box>}
                {privacyPolicyError   && <Box>
                <Alert
                  severity="error"
                  icon={false}
                  style={webstyles.alert}
                  sx={{
                    fontFamily: "Poppins", 
                  }}
                  action={
                    <IconButton
                      aria-label="close"
                      color="inherit"
                      size="small"
                      onClick={() => this.setState({privacyPolicyError:false})}
                      sx={{ color: "#DC2626" }} 
                    >
                      <CloseIcon fontSize="small" />
                    </IconButton>
                  }
                >
               You must agree to the Privacy Policy and Terms & Conditions.
                </Alert>                
                </Box>}
                        <Box>
                            <Box
                                sx={{
                                    width: { xs: "100%", sm: "400px", md: "500px" },
                                    height: "82px", marginBottom: "16px", borderColor: "#CBD5E1",
                                    "& .MuiOutlinedInput-root": {
                                        "&:hover fieldset": {
                                            borderColor: "#CBD5E1", 
                                        },
                                        "&.Mui-focused fieldset": {
                                            borderColor: "#CBD5E1", 
                                        },
                                    },
                                }}
                            >
                                <InputLabel sx={webstyles.labelText}>Name</InputLabel>
                                <TextField type="text" placeholder="John Doe" fullWidth data-testid="firstName"
                                value={firstName} onChange={(e) => this.setState({firstName: e.target.value})}
                                sx={webstyles.nameText} />
                            </Box>
                            <Box
                                sx={{
                                    borderColor: "#CBD5E1",
                                    "& .MuiOutlinedInput-root": {
                                      "&:hover fieldset": {
                                        borderColor: "#CBD5E1",
                                      },
                                      "&.Mui-focused fieldset": {
                                        borderColor: "#CBD5E1",
                                      },
                                    },
                                    width: { xs: "100%", sm: "400px", md: "500px" },
                                    height: "82px", marginBottom: "16px",
                                }}
                            >
                                <InputLabel sx={webstyles.labelText}>Email</InputLabel>
                                <TextField type="email" placeholder="email@example.com" fullWidth data-testid="emailTestId"
                                  value={email}  sx={webstyles.emailText} onChange={(e) => this.setState({email: e.target.value})}
                                />
                            </Box>
                            <Box
                                sx={{
                                    width: { xs: "100%", sm: "400px", md: "500px"},
                                    height: "82px",borderColor: "#CBD5E1",
                                    "& .MuiOutlinedInput-root": {
                                      "&:hover fieldset": {
                                        borderColor: "#CBD5E1",
                                      },
                                      "&.Mui-focused fieldset": {
                                        borderColor: "#CBD5E1",
                                      },
                                    },
                                }}
                            >
                                <InputLabel sx={webstyles.labelText}>Password</InputLabel>
                                <TextField
                                    data-testid="pwdTestID"
                                    sx={webstyles.pwdField}
                                    type={showPassword ? "text" : "password"}
                                    fullWidth
                                    value={newPassword}
                                    onChange={this.handlePasswordChange}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton onClick={this.toggleShowPassword}>
                                                    {showPassword ? <Visibility style={{color:"#94A3B8"}}/> :  
                                                    <VisibilityOffOutlinedIcon style={{color:"#94A3B8"}} />}
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </Box>
                                <Typography
                                    style={{
                                        color: "#0F172A",
                                        fontSize: "12px",
                                        display: "flex",
                                        alignItems: "center",
                                        marginBottom: "8px",
                                        marginTop:"8px",
                                        fontWeight: 400,
                                        fontFamily: "Poppins"
                                    }}
                                >
                                    {this.hasUppercase() && (
                                        <Box display="flex" flexDirection="row">
                                            <CheckCircleIcon style={webstyles.greenCheck} />
                                            <Typography sx={webstyles.pwdCondition}>At least one capital letter</Typography>
                                        </Box>
                                    )}

                                </Typography>
                                <Typography
                                    style={{
                                        color: "#0F172A",
                                        fontSize: "12px",
                                        display: "flex",
                                        alignItems: "center",
                                        marginBottom: "8px",
                                        fontWeight: 400,
                                        fontFamily: "Poppins"
                                    }}
                                >
                                    {this.hasLowercase() && (
                                        <Box display="flex" flexDirection="row">
                                            <CheckCircleIcon style={webstyles.greenCheck} />
                                            <Typography sx={webstyles.pwdCondition}>At least one lowercase letter</Typography>
                                        </Box>)}

                                </Typography>
                                <Typography
                                    style={{
                                        color: "#0F172A",
                                        fontSize: "12px",
                                        display: "flex",
                                        alignItems: "center",
                                        marginBottom: "8px",
                                        fontWeight: 400,
                                        fontFamily: "Poppins"
                                    }}
                                >
                                    {this.hasNumber() && (
                                        <Box display="flex" flexDirection="row">
                                            <CheckCircleIcon style={webstyles.greenCheck} />
                                            <Typography sx={webstyles.pwdCondition}>At least one number</Typography>
                                        </Box>
                                    )}

                                </Typography>
                                <Typography
                                    style={{
                                        marginBottom:"16px",
                                        alignItems: "center",
                                        fontFamily: "Poppins",
                                        color: "#0F172A",
                                        fontSize: "12px",
                                        fontWeight: 400,
                                        display: "flex",
                                    }}
                                >
                                    {this.hasSpcCharacter()
                                        && (
                                            <Box display="flex" flexDirection="row">
                                                <CheckCircleIcon style={webstyles.greenCheck} />
                                                <Typography sx={webstyles.pwdCondition}>At least one special character</Typography>
                                            </Box>
                                        )}
                                </Typography>
                           

                            <Box
                                sx={{
                                    width: { xs: "100%", sm: "400px", md: "500px" },
                                    height: "82px",
                                    mb: 2,borderColor: "#CBD5E1",
                                    "& .MuiOutlinedInput-root": {
                                      "&:hover fieldset": {
                                        borderColor: "#CBD5E1",
                                      },
                                      "&.Mui-focused fieldset": {
                                        borderColor: "#CBD5E1",
                                      },
                                    },
                                }}
                            >
                                <InputLabel sx={webstyles.labelText}>Confirm password</InputLabel>
                                <TextField
                                    data-testid="confirPwdTestID"
                                    value={confirmPassword}
                                    onChange={this.handleConfirmPasswordChange}
                                    type={showConfirmPassword ? "text" : "password"}
                                    fullWidth
                                    sx={webstyles.pwdField}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton onClick={this.toggleShowConfirmPassword}>
                                                    {showConfirmPassword ? <Visibility style={{color:"#94A3B8"}}/> :  
                                                    <VisibilityOffOutlinedIcon 
                                                    style={{color:"#94A3B8"}} />}
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </Box>
                            <Box className="checkboxContainerLast" style={{ display: "flex", alignItems: "center" }}>
                                <label style={{ display: "flex", alignItems: "center", cursor: "pointer" }}>
                                    <input
                                        data-testid="checkbox"
                                        type="checkbox"
                                        checked={isChecked} 
                                        onChange={this.handleCheckboxChange} 
                                        style={webstyles.checkboxStyle(isChecked)} 
                                        name="agree"
                                        data-test-id="agreeCheck"
                                    />
                                    {isChecked && (
                                        <span
                                            style={webstyles.isCheckedStyle}
                                        >
                                            ✓
                                        </span>
                                    )}
                                    <Typography
                                        style={{
                                            fontFamily: "Poppins",
                                            fontWeight: 400,
                                            fontSize: "16px",
                                            color: "#0F172A",
                                        }}
                                    >
                                        I agree with{" "}
                                        <span onClick={this.handlePrivacyNavigation} style={webstyles.policyText}>
                                            Privacy Policy
                                        </span>{" "}
                                        &{" "}
                                        <span onClick={this.handleTermNavigation} style={webstyles.policyText}>
                                            Terms & Conditions
                                        </span>
                                    </Typography>
                                </label>
                            </Box>

                        </Box>
                        <Button
                            data-testid="signupBtn"
                            variant="contained"
                            fullWidth
                            onClick={this.handleSubmit}
                            sx={webstyles.loginButton}
                        >
                            Sign up
                        </Button>
                        <Box style={webstyles.accBox}>
                            <Typography sx={webstyles.signupText}>
                                Already have an account?{" "}
                                <span style={webstyles.signupButton} onClick={this.handleLoginNavigation} >
                                    Log in
                                </span>
                            </Typography>
                        </Box>
                    </Box>
                </Box>
                {/* Right Section */}
                <Box
                    sx={webstyles.cooksyImgBox}
                >
                    <img
                        src={sideImage}
                        alt="images"
                        style={webstyles.cooksyImg}
                    />
                </Box>
            </Box>

            // Customizable Area End
        );
    }

}

const webstyles = {
    loginText: {
        fontFamily: "Poppins !important",
        fontWeight: "700",
        fontSize: 24,
        color: "#000000"
    },
    cooksyLogo:{
        width: "182px", 
        height: "66px"
    },
    topBox:{
        width: { xs: "95%",lg:"100%"},
        flexDirection: { md: "row", xs: "column" },
        justifyContent: "center",
        alignItems: { xs: "center", md: "flex-start" },
        display: "flex",
    },
    isCheckedStyle: {
        position: "absolute" as "absolute",
        left: "124px",
        fontSize: "14px",
        fontWeight: "bold",
        color: "#FFFFFF",
    },
    checkboxStyle: (isChecked:any) => ({
        appearance: "none" as "none",
        WebkitAppearance: "none" as "none",
        MozAppearance: "none" as "none",
        width: "20px",
        height: "20px",
        border: "2px solid #6E7781",
        borderRadius: "6px",
        outline: "none",
        marginRight: "8px",
        cursor: "pointer",
        backgroundColor: isChecked ? "#44041C" : "transparent",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        position: "relative" as "relative",
      }),
    leftBox:{
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        margin: { md: "39px 95px 0px 115px", xs: "32px auto 25px 26px" },
        display: "flex",
        width: { md: "auto", xs: "83%" },
    },
    checkboxInput: {
        width: "20px",
        height: "20px",
        borderRadius: "6px"
    },
    welcomeText: {
        fontFamily: "Poppins !important",
        fontWeight: "400",
        fontSize: 16,
        color: "#475467"
    },
    greenCheck:{
        color: "#34D399",
        width: "12px", 
        height: "12px", 
        marginRight: "3px",
        marginTop:"2px"
    },
    pwdCondition:{
        fontFamily: "Poppins !important",
        fontWeight: "400",
        fontSize: "12px",
        color: "#0F172A"
    },
    labelText: {
        fontFamily: "Poppins !important",
        fontWeight: "700",
        fontSize: 14,
        color: "#334155",
        marginBottom:"4px"
    },
    policyText:{
        fontFamily: "Poppins !important",
        textDecoration: "underline", 
        cursor: "pointer"
    },
    pwdField:{
        mb: 2,
        borderRadius: "8px",
        "& .MuiOutlinedInput-root":{
            borderRadius: "8px",
            border:"1px solid #CBD5E1"
        },
        "& .MuiInputBase-input::placeholder": {
            fontFamily: "Poppins",
            color: "#94A3B8", 
            fontWeight: 400, 
            fontSize: "16px",
        },
        "& .MuiInputBase-input": {
            fontFamily: "Poppins", 
        },
        "& .MuiOutlinedInput-root fieldset": {
            border: "none", 
        },
        "& .MuiOutlinedInput-notchedOutline": {
            border: "none",
        },
    },
    nameText:{
        mb: 2, 
        borderRadius: "8px",
        "& .MuiInputBase-input": {
            fontFamily: "Poppins", 
        },
        "& .MuiOutlinedInput-root":{
            borderRadius: "8px",
            border:"1px solid #CBD5E1"
        },
        "& .MuiInputBase-input::placeholder": {
            fontFamily: "Poppins",
            fontWeight:400,
            fontSize:"16px",
            color:"#94A3B8"
        },
        "& .MuiOutlinedInput-root fieldset": {
            border: "none", 
        },
        "& .MuiOutlinedInput-notchedOutline": {
            border: "none",
        },
    },
    emailText:{
        mb: 2, 
        borderRadius: "8px",
        "& .MuiOutlinedInput-root":{
            borderRadius: "8px",
            border:"1px solid #CBD5E1"
        },
        "& .MuiInputBase-input": {
            fontFamily: "Poppins", 
        },
        "& .MuiInputBase-input::placeholder": {
            fontFamily: "Poppins",
            color: "#94A3B8", 
            fontWeight: 400, 
            fontSize: "16px",
        },
        "& .MuiOutlinedInput-root fieldset": {
            border: "none", 
        },
        "& .MuiOutlinedInput-notchedOutline": {
            border: "none",
        },
    },
    cooksyImgBox: {
        padding: "16px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: {lg:"100%", xs: "83%", md: "100%", },
        margin:{ xs:"0px 18px 0px 0px",lg:"auto",md:"auto"},
    },
    forgotText: {
        fontFamily: "Poppins !important",
        fontWeight: "700",
        fontSize: 14,
        color: "#44041C",
        textAlign: "right" as "right",
        width: "100%",
        marginBottom: "32px",
    },
    alert:{
        marginTop: "32px",
        marginBottom:"32px", 
        borderRadius: "4px", 
        padding: "8px 16px", 
        borderLeft: "4px solid #DC2626", 
        backgroundColor: "#FEE2E2", 
        color: "#DC2626",
        fontFamily:"Poppins !important",fontWeight:400,fontSize:"12px"
      },
    loginButton: {
        borderRadius:"8px",
        fontFamily: "Poppins !important",
        fontWeight: "700",
        fontSize: 16,
        color: "#FFFFFF",
        backgroundColor: "#44041C",
        height: "56px",
        textTransform: "none" as "none",
        marginBottom: "24px",
        marginTop: "32px",
        width: { xs: "100%", sm: "400px", md: "500px" },
        '&:hover': {
            backgroundColor: "#44041C",
        },
    },
    signupButton: {
        fontFamily: "Poppins !important",
        fontWeight: "700",
        fontSize: 16,
        color: "#44041C",
        textDecorationLine: "underline",
        cursor: "pointer !important"
    },

    cooksyImg: {
        width: "100%",
        maxWidth: "698px",
        borderRadius: 24,
        minHeight: "868px",
        height: "100%"
    },
    signupText: {
        fontFamily: "Poppins !important",
        fontWeight: "400",
        fontSize: 16,
        color: "#0F172A",
        cursor:"pointer !important"
    },
    accBox:{
        marginTop:"24px",
        textAlign:"center" as "center"
    }
}
